<template>
	<div class="">
		<div class="list-items">
			<div class="flex align-center justify-between" >
				<router-link :to="{path:'/talents_detail',query:{user_id:item.user_id}}">
					<div class="flex align-center writer-info" v-if="item.user">
						<div class="head-box">
							<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48" fit="cover"></el-image>
							<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
						</div>
						<div class="left-info">
							<p class="name">{{item.user.user_nickname}}</p>
							<div class="writer-tag flex align-center margin-top-5" v-if="item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
								<p type="warning"  v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
							</div>
						</div>
					</div>
				</router-link>
				<div class="">
					<span class="time margin-right-xs">{{item.push_time}}</span>
					<el-dropdown trigger="click" v-if="userInfo && item.user_id!=userInfo.id">
						<span class="el-dropdown-link">
							<i class="el-icon-more point"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item class="clearfix margin-bottom-10">
								<div class="flex align-center" @click="addFocus">
									<img src="../../assets/image/home/collect-gray.png" class="block-18 margin-right-10">
									<p>{{item.is_focus==1 ? '取消关注' :'关注'}}</p>
								</div>
							</el-dropdown-item>
							<el-dropdown-item class="clearfix margin-bottom-10">
								<router-link :to="{path:'/report',query:{aid:item.aid}}">
									<div class="flex align-center">
										<img src="../../assets/image/home/report.png" class="block-18 margin-right-10">
										<span class="text-333">举报</span>
									</div>
								</router-link>
							</el-dropdown-item>
							<el-dropdown-item class="clearfix">
								<div class="flex align-center" @click="setBlack(item.user_id)">
									<img src="../../assets/image/home/setblack.png" class="block-18 margin-right-10">
									<span class="text-333">拉黑</span>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="" v-if="item.ducoment_type==0">
				<p class="fs16 margin-top-xs">{{item.transmit_desc}}</p>
				<div class="margin-top fs24 bg-gray margin-bottom-sm point" @click="toTransDetail(item)">
					<div class="flex padding-bottom-sm" >
						<div class=" margin-left writer-info">
							<div class="flex align-center justify-between  padding-top-xs">
								<div class="writer-info flex align-center margin-bottom-xs">
									<div class="head-box fs16 text-theme bold ">
										转自：
									</div>
									<div class="head-box">
										<el-image :src="item.from_user_head_img" class="head-img margin-right-xs radius-48" fit="cover"></el-image>
										<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
									</div>
									<div class="left-info">
										<p class="name fs16">{{item.from_user_nickname || '--'}}</p>
									</div>
								</div>
							</div>
							<div class="writer-tag flex align-center margin-top-5" v-if="item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
								<p type="warning"  v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
							</div>
							<div style="display:flex;">
								<div  v-if="item && item.images" class="images-box" style="flex-shrink: 0;">
							<div  v-for="(img_item,img_index) in item.images" :key="img_index">
								<div class="grid-content bg-purple img-box" v-if="item.is_video==1 && img_index==0">
									<el-image :src="img_item+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_100,h_100,m_fast'" fit="cover" class="width100 height100" :preview-src-list="item.images" style="display: block;"></el-image>
								</div>
								<div class="grid-content bg-purple img-box"  v-if="item.is_video==0 && img_index==0">
									<el-image :src="img_item"  fit="cover" class="width100 height100 " :preview-src-list="item.images" style="display: block;"></el-image>
								</div>
							</div>
						</div>
							<div class="fs14 text-666 " v-if="item.transmit_type==1 || item.transmit_type==2">
								<div class="flex align-center" v-if="item.transmit_type==2">
									<!-- <div class="ftag fs12 margin-right-10" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
										{{item.is_free==1 ? '免费' : '收费'}}
									</div> -->
									<p class="bold fs16 margin-tb-20 text-333">{{item.title}}</p>
								</div>
								<div class="text-2em text-cut " style="height: 36px;line-height: 36px;" v-html="setContent(item.body)" v-if="item.transmit_type==2 && (item.is_buy==1 || item.is_free==1) "></div>
								<div class="text-2em text-cut " style="height: 36px;line-height: 36px;"  v-if="item.transmit_type==1">{{item.body}}</div>
							</div>
							</div>
							<!-- 电子书 start -->
							<div class="book-area" v-if="item.transmit_type==3">
								<div class="book-list-items padding-top-20 flex margin-bottom point" @click="toDetail(2)">
									<div class="book-cover margin-right-sm">
										<img :src="item.thumb | filterArray">
										<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
											{{item.is_free==1 ? '免费' : '收费'}}
										</div>
									</div>
									<div class="right-info flex flex-direction  justify-around">
										<div class="flex align-center title-box">
											<p class="book-title text-cut">{{item.title}}</p>
											<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
												{{item.is_continued==1 ? '连载' : '完结'}}
											</div>
										</div>
										<div class="flex align-center writer-info">
											<div class="" style="flex-shrink: 0;">
												<div class="head-box">
													<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
													<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
												</div>
												<!-- <img :src="item.user.head_img" class="block-48 radius-48 margin-right-xs"> -->
											</div>
											<div class="left-info">
												<p class="name">{{item.writer_profile}}</p>
												<div class="writer-tag flex align-center margin-top-5" v-if="item.user && item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
													<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
												</div>
											</div>
										</div>
										<div class="flex align-center">
											<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
											<p class="fs12">发布时间：{{item.create_time}}</p>
										</div>
									</div>
								</div>
							</div>
							<!-- 电子书 end -->
						</div>
					</div>
				</div>
			</div>
			<div class="point margin-bottom-15" v-if="item.ducoment_type==1 || item.ducoment_type==2">
				<div class="inner-content margin-tb-xs" @click='toDetail(1)'>
					<div class="flex align-center" v-if="current==2 || current==4">
						<div class=" fs12 margin-right-10 representative_tag" v-if="item.is_representative==1">
							代表作
						</div>
						<div class=" fs12 margin-right-10 representative_tag" v-if="item.is_competition==1">
							参赛作品
						</div>
						<!-- <div class="ftag fs12 margin-right-10 fee_tag" v-if="item.is_competition!=1 && item.is_free!=1" >
							{{item.is_free==1 ? '免费' : '收费'}}
						</div> -->
						<p class="bold fs16 margin-tb-10 text-333">{{item.title || '暂无标题'}}</p>
						<p class="bold fs16 margin-tb-10 text-blue margin-left-10" @click.stop="toGameDetail(item.event_aid)" v-if="item.is_competition==1 && item.event_name">#{{item.event_name}}</p>
					</div>
					<p class="text-666 fs14 html-content" style="word-break: break-all;"  v-if="current==1" v-html="item.body"></p>
				</div>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==0">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<el-image :src="img_item" fit="cover" class="w100 height148 radius-6" :preview-src-list="item.images"></el-image>
					</el-col>
				</el-row>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==1">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<video :src="img_item" controls="controls" width="400" height="">
							当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="img_item">下载视频</a>
						</video>
					</el-col>
				</el-row>
				
			</div>
			<!-- 电子书 start -->
			<div class="book-area" v-if="item.ducoment_type==3">
				<div class="book-list-items padding-top-20 flex margin-bottom point" @click="toDetail(2)">
					<div class="book-cover margin-right-sm">
						<img :src="item.thumb | filterArray">
						<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
							{{item.is_free==1 ? '免费' : '收费'}}
						</div>
					</div>
					<div class="right-info flex flex-direction  justify-around">
						<div class="flex align-center title-box">
							<p class="book-title text-cut">{{item.title}}</p>
							<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
								{{item.is_continued==1 ? '连载' : '完结'}}
							</div>
						</div>
						<div class="flex align-center writer-info">
							<div class="" style="flex-shrink: 0;">
								<div class="head-box">
									<el-image :src="item.user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
									<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.user.is_v==1">
								</div>
								<!-- <img :src="item.user.head_img" class="block-48 radius-48 margin-right-xs"> -->
							</div>
							<div class="left-info">
								<p class="name">{{item.writer_profile}}</p>
								<div class="writer-tag flex align-center margin-top-5" v-if="item.user && item.user.tags_info && item.user.tags_info.length>0 && item.user.tags_info[0]!=''">
									<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in item.user.tags_info" :key="j">{{ele}}</p>
								</div>
							</div>
						</div>
						<div class="flex align-center">
							<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
							<p class="fs12">发布时间：{{item.create_time}}</p>
						</div>
						<el-row :gutter="24">
							<el-col :span="8">
								<div class="point flex align-center">
									<img src="../../assets/image/icon/view.png" class="block-20 margin-right-5">
									<span class="fs14 text-999" >{{item.read_num || 0}}</span>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="grid-content bg-purple">
									<div class="fs12 flex align-center">
										<img src="../../assets/image/home/zan1.png" class="block-16 margin-right-5" v-if="item.is_like==1" key="like">
										<img src="../../assets/image/home/zan.png" class="block-16 margin-right-5" v-else key="unlike">
										<!-- <img src="../../assets/image/home/like.png" class="block-16 margin-right-5"> -->
										<span :class="item.is_like==1 ? 'text-theme' : 'text-999' ">{{item.like_num || 0}}</span>
									</div>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="point flex align-center" >
									<img src="../../assets/image/home/message.png" class="block-20 margin-right-5">
									<span class="fs14 text-999" >{{item.comm_num || 0}}</span>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<!-- 电子书 end -->
			<!-- 底部操作 -->
			<div class="flex align-center justify-around" v-if="item.ducoment_type!=3">
				<!-- <div class="point flex align-center" @click="toCollect(item)">
					<transition :name=" item.is_collect==1 ? 'zoom' : '' " mode="out-in">
						<img src="../../assets/image/icon/collect_fill.png" class="block-22 margin-right-5"
							v-if="item.is_collect==1" key="collect">
						<img src="../../assets/image/icon/collect.png" class="block-22 margin-right-5" v-else
							key="uncollect">
					</transition>
					<span class="fs14 "
						:class="item.is_collect==1 ? 'text-theme' : 'text-999' ">{{item.is_collect==1 ? '取消收藏' :'收藏'}}</span>
				</div> -->
				<div class="point flex align-center">
					<el-popover
					    placement="bottom"
					    title=""
					    width="200"
					    trigger="hover">
						<div class="point">
							<div class="flex align-center"  @click="copyUrl(item)">
								<img src="../../assets/image/icon/copy.png" class="block-20 margin-right-10">
								<p class="text-666">复制链接</p>
							</div>
							<div class="margin-top flex align-center" @click="transpond(item)" v-if="item.ducoment_type!=0">
								<img src="../../assets/image/icon/transpond.png" class="block-24 margin-right-10">
								<p class="text-666">转发</p>
							</div>
						</div>
						<div class="flex align-center" slot="reference">
							<img src="../../assets/image/home/share.png" class="block-22 margin-right-5">
							<span class="fs14 text-999">{{item.transmit_num || 0}}</span>
						</div>
					</el-popover>
				</div>
				<div class="point flex align-center"  @click.stop='toDetail(1)'>
					<img src="../../assets/image/home/read.png" class="block-22 margin-right-5">
					<span class="fs14 text-999">{{item.read_num || 0}}</span>
				</div>
				<div class="point flex align-center" @click="giveLike(item)">
					<transition :name=" item.is_like==1 ? 'zoom' : '' " mode="out-in">
						<img src="../../assets/image/home/zan1.png" class="block-22 margin-right-5"
							v-if="item.is_like==1" key="like">
						<img src="../../assets/image/home/zan.png" class="block-22 margin-right-5" v-else key="unlike">
					</transition>
					<span class="fs14 text-999"
						:class="item.is_like==1 ? 'text-theme' : 'text-999' ">{{item.like_num || 0}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// 是否加推荐
			is_remind: Boolean,
			current: [Number, String],
			item: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				baseUrl:this.imgUrl
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		Updated() {
			setTimeout(()=>{
				let DomList=document.getElementsByClassName('inner-content')[0].querySelectorAll('img');
				for(let i in  DomList){
				  if( DomList[i].style){
					 DomList[i].style.width='60%'
				  }
				}
			},1000)
			
		
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo;
			},
		},
		methods: {
			setContent(res){
				const regex = new RegExp('<img', 'gi')
				return res.replace(regex, `<img style="max-width: 60%; height: auto;display:none"`)
			},
			// 跳转赛事详情
			toGameDetail(id){
				this.toBlank({
					path:'/center_detail',
					query:{
						event_aid:id
					}
				})
			},
			// 复制链接
			copyUrl(item){
				let invitelink = `${this.baseUrl}/index/index/pc/#/artileDetail?article_id=${item.aid}&atype=${this.current+1}`;
				this.$copyText(invitelink).then(
					res => {
						this.$message.success('成功复制到剪贴板')
					},
					err => {
						console.log(err) // 同上
					}
				)
			},
			// 拉黑
			setBlack(black_uid){
				this.$confirm('确认拉黑该用户？拉黑后，该用户将不能关注您并无法对您的作品进行评论', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/61ee0aa299200', {
				  	black_uid: black_uid
				  }, res => {
				  	if (res.code == 1) {
				  		this.$message.success(res.msg);
				  	} else {
				  		this.$message.error(res.msg);
				  	}
				  })
				}).catch(() => {
				           
				});
				
			},
			// 转发
			transpond(item){
				this.$router.push({
					path:'/transpond',
					query:{
						ducoment_type:item.ducoment_type,
						aid:item.aid
					}
				})
			},
			// 跳转至转发的文章
			toTransDetail(item){
				this.toBlank({
					path:'/artileDetail',
					query: {
						article_id: item.transmit_aid,
						atype: item.transmit_type
					}
				})
			},
			// 点赞	
			giveLike() {
				this.ajax('post', '/v1/61419389e89d5', {
					article_id: this.item.aid
				}, res => {
					if (res.code == 1) {
						this.item.is_like = this.item.is_like == 1 ? 0 : 1;
						if (this.item.is_like == 1) {
							this.item.like_num += 1
						} else {
							this.item.like_num -= 1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 收藏
			toCollect() {
				this.ajax('post', '/v1/6141a0f6bd7d0', {
					article_id: this.item.aid,
					type: this.current
				}, res => {
					if (res.code == 1) {
						this.item.is_collect = this.item.is_collect == 1 ? 0 : 1;
						if (this.item.is_collect == 1) {
							this.item.collect_num += 1
						} else {
							this.item.collect_num -= 1
						}
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 加关注
			addFocus() {
				this.ajax('post', '/v1/61419cb4727bd', {
					fuid: this.item.user_id
				}, res => {
					if (res.code == 1) {
						this.item.is_focus = this.item.is_focus == 1 ? 0 : 1;
						this.$emit('addFocus')
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			toDetail(type) {
				if(type==1){
					// 帖子详情
					this.toBlank({
						path: '/artileDetail',
						query: {
							article_id: this.item && this.item.aid,
							atype: this.current
						}
					});
				}else{
					// 电子书详情
					this.toBlank({
						path:'/fictionDetail',
						query:{
							aid:this.item && this.item.aid
						}
					})
				}
				
				return;
				if (this.current == 1) {
					// /作品详情
					this.$router.push('/worksDetail')
				} else {
					// 帖子详情
					this.$router.push({
						path: '/artileDetail',
						query: {
							article_id: this.item && this.item.aid
						}
					})
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	/** 动画进行时的class **/
	.zoom-enter-active,
	.zoom-leave-active {
		transition: all .15s cubic-bezier(0.42, 0, 0.34, 1.55);
	}

	/** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
	.zoom-enter,
	.zoom-leave-to {
		transform: scale(0);
	}

	/** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
	.zoom-enter-to,
	.zoom-leave {
		transform: scale(1);
	}
	.images-box{
		// width: 100px !important;
		// height: 100px;
	}
	.img-box{
		width: 100px !important;
		height: 100px;
		margin-right: 20px;
		.el-image{
			width: 100px !important;
			height: 100px;
		}
	}
	.text-blue{
		color: #007AFF;
	}
	.representative_tag{
		min-width: 46px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
		color: #E54F42;
		background: #FDEDEB;
		padding: 2px;
	}
	.ftag {
		width: 46px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
	}

	.free_tag {
		color: #31CBA4;
		background: #CAF4D7;
	}

	.fee_tag {
		color: #FF7F37;
		background: #FDEDEB;
	}

	.title {
		height: 21px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 21px;
		color: #333333;
		opacity: 1;
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
	.list-items {
		padding: 20px 0;
		border-bottom: 1px solid #EBEEF5;
	}

	.grid-content {
		img {
			width: 100%;
			;
		}
	}

	.writer-info {
		.head-img {
			width: 48px;
			height: 48px;
		}

		.left-info {
			.name {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 66px;
				height: 20px;
				text-align: center;
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #FFFFFF;
				opacity: 1;
				&:nth-child(1){
					background: url(../../assets/image/icon/label-bg1.png) no-repeat;
					background-size: cover;
				}
				&:nth-child(2){
					background: url(../../assets/image/icon/label-bg2.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}

	.inner-content {}

	.time {
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 11px;
		color: #999999;
		opacity: 1;
	}
	// 电子书模块 start
	.book-area{
		.book-cover {
			width: 104px;
			height: 146px;
			background: rgba(0, 0, 0, 0);
			opacity: 1;
			border-radius: 8px 0px 0px 8px;
			position: relative;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 8px 0px 0px 8px;
			}
			.ftag {
				position: absolute;
				top: 0;
				right: 0;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
		.head-box{
			position: relative;
			.bigv{
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
		}
		.right-info {
			height: 146px;
			.title-box{
				.ftag {
					margin-left: 10px;
					width: 36px;
					text-align: center;
					height: 20px;
					line-height: 20px;
					border-radius: 0 0 0 10px;
					font-weight: bold;
				}
				
				.free_tag {
					color: #31CBA4;
					background: #CAF4D7;
				}
				
				.fee_tag {
					color: #FF7F37;
					background: #FDEDEB;
				}
			}
		}
		
		.book-title {
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			opacity: 1;
		}
		
		.writer-info {
			.head-img {
				width: 29px;
				height: 29px;
			}
		
			.left-info {
				.name {
					font-size: 11px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					opacity: 1;
				}
			}
		
			.writer-tag {
				p {
					width: 66px;
					height: 20px;
					text-align: center;
					opacity: 1;
					font-size: 8px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #FFFFFF;
					opacity: 1;
					&:nth-child(1){
						background: url(../../assets/image/icon/label-bg1.png) no-repeat;
						background-size: cover;
					}
					&:nth-child(2){
						background: url(../../assets/image/icon/label-bg2.png) no-repeat;
						background-size: cover;
					}
				}
			}
		}
	}
</style>
